import authCrudder from './auth-crud';
import survey from './survey';
import { API } from '../constants/urls';
import { executeCall } from './utils';
import Axios from 'axios';
import IUser from 'core/interfaces/IUser';

export const authAPI = authCrudder(API, "auth/access-list");
export const surveyAPI = survey(API, "survey");
export const getUsersOfGroup = ({ groupId, division, role }: { groupId: number, division?: number | null, role?: number | null }) => executeCall(() => Axios.get<IUser[]>(`${API}/security/user/ofgroup/public/${groupId}/${!!division ? division : '0'}/${!!role ? role : '0'}`));
export const getIP = (): Promise<string> => executeCall(async (): Promise<string> => {
    try {
        const { data } = await Axios.get<string>('https://www.cloudflare.com/cdn-cgi/trace');
        const ipData = {} as { [key: string]: string };
        for(const pair of data.trim().split('\n')) {
            const tupple = pair.split('=');
            ipData[tupple[0]] = tupple[1];
        }
        return ipData['ip'] ?? '';
    } catch (e) {
        console.log(e);
        return ""
    }
});
