import { Stars } from "@etiquette-ui/misc";
import useI18n from "i18n/useI18n";
import { XetuxProduct, DataProduct } from "./Types";
import { TextArea } from "@etiquette-ui/inputs-dropdowns";
import Styles from "./Product.module.css";
import Translation from "core/render/interfaces/Translation";

type Props = {
  id: string,
  product: XetuxProduct;
  imgUrl: string;
  value: DataProduct;
  setProduct: (product: DataProduct) => void;
  textAreaTranslation: string;
};

const Product = ({
  product,
  imgUrl,
  value,
  setProduct,
  textAreaTranslation,
  id,
}: Props) => {
  const { translateObject } = useI18n();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProduct({
      ...value,
      comment: e.target.value,
    });
  };

  const handleRating = (rating: number) => {
    setProduct({
      ...value,
      rating,
    });
  };

  const productTitleTranslation = (): string => {
    const translation: Translation = {
      en: `${product.translations.title.en}`,
      es: `${product.translations.title.es}`,
    };

    return translateObject(translation);
  }

  const rateTranslation = (): string => {
    // {} Your rated {value.rating} stars
    const translation: Translation = {
      en: `Your rated ${value.rating} stars`,
      es: `Calificado con ${value.rating} estrellas`,
    };

    return translateObject(translation);
  };

  return (
    <div className={Styles.container} id={id}>
      <div className={Styles.title}>
        <span>{productTitleTranslation()}</span>
      </div>
      <Stars value={value.rating} setValue={handleRating} />
      <div className={Styles.description}>{rateTranslation()}</div>
      <div className={Styles.image}>
        <img src={`${imgUrl}${product.img}`} alt={product.translations.title.en} />
      </div>
      <TextArea
        inputProps={{
          onChange: handleChange,
          value: value.comment,
        }}
        iconProps={{
          icon: 'comment'
        }}
        labelProps={{
          label: textAreaTranslation,
        }}
      />
    </div>
  );
};

export default Product;
