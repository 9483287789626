import React, { useEffect, useMemo, useState } from "react";
import WaiterOption from "../types/WaiterOption";
import AnswerType from "../types/AnswerType";
import { PreSurveyStatus } from "core/presurvey/interfaces/PreSurveyComponent";
import useSettings from "state/settings";
import formValidation from "../formValidation";
import useGlobalVariables from "state/globalVariables";
import useWaiters from "./useWaiters";
import useTicket from "./useTickets";

export type DataContextType = {
    loading: boolean;
    waiters: WaiterOption[];
};

const DataContext = React.createContext<DataContextType>({
    loading: false,
    waiters: [],
});

export const useData = () => React.useContext(DataContext);

export const DataProvider: React.FC<{
    children: any;
    id: string;
    employeesUrl: string;
    serverUserTypes: string[];
    ticketsUrl: string;
    section: AnswerType | null;
}> = ({ children, id, employeesUrl, serverUserTypes, ticketsUrl, section }) => {
    const { dispatcher: settingsDispatcher } = useSettings();
    const { state: { variables } } = useGlobalVariables();

    const { loading: loadingWaiters, waiters } = useWaiters(employeesUrl, serverUserTypes);
    const { loading: loadingTicket } = useTicket(id, ticketsUrl, section?.ticket?.ticketN);

    const loading = useMemo(() => loadingWaiters || loadingTicket, [loadingWaiters, loadingTicket]);

    useEffect(() => {
        if (loading) {
            settingsDispatcher.setConfigSectionsStatus(id, PreSurveyStatus.LOADING);
            return;
        }
        if (!loading && formValidation(section, variables[id])) {
            settingsDispatcher.setConfigSectionsStatus(id, PreSurveyStatus.VALID);
            return;
        }

        settingsDispatcher.setConfigSectionsStatus(id, PreSurveyStatus.ERROR);
    }, [settingsDispatcher, loading, section, variables, id]);

    return (
        <DataContext.Provider value={{ loading, waiters }}>
            {children}
        </DataContext.Provider>
    );
};
