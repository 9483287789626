import { FC, useMemo } from "react";
import PreSurveyComponent from "core/presurvey/interfaces/PreSurveyComponent";
import Ticket from "./Ticket";
import usePreSurvey from "state/presurvey";
import SettingsType from "./types/SettingsType";
import { DataProvider } from "./data";
import { Container } from "./styles";

const Preform: FC<PreSurveyComponent<SettingsType>> = ({ id, settings }) => {
    const {
        state: { sections },
    } = usePreSurvey();

    const section = useMemo(() => {
        const section = sections[id];
        if (section?.type === "xetux-config") return section;
        return null;
    }, [sections, id]);

    return (
        <DataProvider
            waitersUrl={settings.waitersUrl}
            ticketsUrl={settings.ticketUrl}
            section={section}
            id={id}
        >
            <Container>
                <Ticket id={id} section={section} />
            </Container>
        </DataProvider>
    );
};

export default Preform;
