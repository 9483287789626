import axios from "axios";
import { useEffect, useState } from "react";
import WaiterResponse from "../types/WaiterResponse";
import WaiterOption from "../types/WaiterOption";

const useWaiters = (url: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [waiters, setWaiters] = useState<WaiterOption[]>([]);
    useEffect(() => {
        if (!url) return;
        setLoading(true);
        axios
            .get<WaiterResponse[]>(url)
            .then((res) => {
                const options = res.data.map((waiter: WaiterResponse) => ({
                    label: waiter.name,
                    value: +waiter.userId,
                }));

                setWaiters(options);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [url]);
    return { loading, waiters };
};

export default useWaiters;
