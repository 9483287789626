import React, { useEffect, useState } from 'react';
import IXetuxProducts from './IXetuxProducts';
import { BasicQuestionComponent } from 'core/render/interfaces/QuestionComponent';
import { XetuxProduct, DataProduct } from './Types';
import Translation from 'core/render/interfaces/Translation';
import Product from './Product';
import Styles from './Product.module.css';
import useI18n from 'i18n/useI18n';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useScroll } from 'providers/scroll';
import AnimateTransition from 'components/Animate';
import { cleanId } from 'core/utils';
import { MENU_IMAGES_URL } from 'resources/constants/urls';
import SectionTitle from 'components/SectionTitle';
import styled from 'styled-components';
import { DISABLED_BUTTON } from '@etiquette-ui/colors';
import useGlobalVariables from 'state/globalVariables';

const Subtitle = styled.p`
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
`;

const XetuxProductComponent: React.FC<BasicQuestionComponent<IXetuxProducts>> = ({ id, question, dataAccess }) => {
    const { translateObject } = useI18n();
    const [list, setList] = useState<XetuxProduct[]>([]);
    const {
        state: { variables },
    } = useGlobalVariables();
    const [response, setResponse] = dataAccess();
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalRated, setTotalRated] = useState<number>(0);
    const { next, registerQuestion } = useScroll();
    const questionId = id;

    useEffect(() => {
        const products = filterProducts();
        const list = new Map<number, XetuxProduct>();
        products.forEach((product) => list.set(product.productId, product));
        setList(Array.from(list.values()));
        registerQuestion(id, true);
        return () => {
            registerQuestion(id, false);
        };
    }, []);

    useEffect(() => {
        const response = list.map((product) => ({
            productId: product.productId,
            categories: product.categories,
            rating: 0,
            comment: '',
            name: product.translations,
        }));

        setResponse(response);
        setTotalItems(list.length);
    }, [list]);

    useEffect(() => {
        if (!response) return;

        const totalRated = response.reduce((acc: number, curr: DataProduct) => {
            if (curr.rating > 0) {
                return acc + 1;
            }
            return acc;
        }, 0);

        setTotalRated(totalRated);
    }, [response]);

    function filterProducts(): XetuxProduct[] {
        const products = variables![question.configKey] as XetuxProduct[];

        return products.filter((p) => existsOnFamily(p));
    }

    function existsOnFamily(product: XetuxProduct) {
        return product.family === question.family;
    }

    const setProduct = (i: number, id: number) => (data: DataProduct) => {
        const newResponse = [...response];
        newResponse[i] = { ...response[i], ...data };
        setResponse(newResponse);

        const product = list.find((el) => el.productId === id);
        if (product === undefined) return;
        const index = list.indexOf(product);
        if (index === -1) return;
        if (index === list.length - 1 && response[i].rating !== data.rating && data.rating > 0) {
            next(cleanId(questionId));
            return;
        }
        const element = document.getElementById(`${list[index + 1].productId}`);
        if (!!element && response[i].rating !== data.rating && data.rating > 0)
            smoothScrollIntoView(element, {
                scrollMode: 'always',
                behavior: 'smooth',
                inline: 'center',
                block: 'nearest',
            });
    };

    const rateTranslation = (): string => {
        const translation: Translation = {
            en: `${totalRated} of ${totalItems} items rated.`,
            es: `${totalRated} de ${totalItems} calificados.`,
        };
        return translateObject(translation);
    };

    return (
        <AnimateTransition id={cleanId(id)}>
            <div>
                {!!question.title && (
                    <SectionTitle style={{ textAlign: 'center' }}>{translateObject(question.title)}</SectionTitle>
                )}
                {!!question.subtitle && <Subtitle style={{ textAlign: 'center' }}>{translateObject(question.subtitle)}</Subtitle>}
                <div className={Styles.rated}>{rateTranslation()}</div>
                <div className={Styles.list}>
                    {!!response &&
                        response.length > 0 &&
                        list.map((p, i) => (
                            <>
                                <Product
                                    key={p.productId}
                                    id={`${p.productId}`}
                                    product={p}
                                    imgUrl={MENU_IMAGES_URL}
                                    value={response[i]}
                                    setProduct={setProduct(i, p.productId)}
                                    textAreaTranslation={!!question.commentBoxTitle ? translateObject(question.commentBoxTitle) : ''}
                                />
                                {i < list.length - 1 && (
                                    <div
                                        style={{
                                            flex: 1,
                                            width: '3px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '0 5px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: DISABLED_BUTTON,
                                                height: '40%',
                                                width: '3px',
                                                borderRadius: '5px',
                                                zIndex: -1000,
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        ))}
                </div>
            </div>
        </AnimateTransition>
    );
};

export default XetuxProductComponent;
