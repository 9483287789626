import { FC } from "react";
import { Dropdown, Input } from "@etiquette-ui/inputs-dropdowns";
import Styles from "./Styles.module.css";
import useI18n from "i18n/useI18n";
import {
    tableLabel,
    waiterLabel,
    amountLabel,
    guestLabel,
    waiterGuestsLabel,
    waiterPlaceholder,
} from "./translations";
import styled from "styled-components";
import AnswerType from "./types/AnswerType";
import { useData } from "./data";
import useUpdateAnswer from "./data/useUpdateAnswer";
import WaiterOption from "./types/WaiterOption";

export type Employee = {
    userId: number;
    name: string;
};

const WaiterDropdown = styled(Dropdown)`
    .selector__menu-list {
        max-height: 170px;
    }
`;

const Ticket: FC<{
    id: string;
    section: AnswerType | null;
}> = ({ id, section }) => {
    const { translateObject } = useI18n();
    const { waiters } = useData();
    const { updateTicket, updateWaiter } = useUpdateAnswer(id, section);

    return (
        <>
            <div className={Styles.row}>
                <div className={Styles.middleColumn}>
                    <Input
                        iconProps={{
                            icon: "number",
                        }}
                        labelProps={{
                            label: "Ticket",
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("ticketN", +e.currentTarget.value),
                            value: section?.ticket?.ticketN ?? "",
                            type: "number",
                        }}
                    />
                </div>
                <div className={Styles.middleColumn}>
                    <Input
                        iconProps={{
                            icon: "number",
                        }}
                        labelProps={{
                            label: translateObject(tableLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("table", +e.currentTarget.value),
                            value: section?.ticket?.table ?? "",
                            type: "number",
                            disabled: true,
                        }}
                    />
                </div>
            </div>

            <div className={Styles.row}>
                <div className={Styles.completeColumn}>
                    <WaiterDropdown
                        labelProps={{
                            label: translateObject(waiterLabel),
                        }}
                        selectProps={{
                            onChange: (e: WaiterOption) =>
                                updateWaiter({
                                    value: e.value,
                                    label: e.label,
                                }),
                            options: waiters,
                            placeholder: translateObject(waiterPlaceholder),
                            value: section?.waiter
                                ? {
                                      label: section.waiter.label,
                                      value: section.waiter.value,
                                  }
                                : null,
                        }}
                    />
                </div>
            </div>

            <div className={Styles.row}>
                <div className={Styles.middleColumn}>
                    <Input
                        iconProps={{
                            icon: "price",
                        }}
                        labelProps={{
                            label: translateObject(amountLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("amount", +e.currentTarget.value),
                            value: section?.ticket?.amount ?? "",
                            type: "number",
                            disabled: true,
                        }}
                    />
                </div>
                <div className={Styles.middleColumn}>
                    <Input
                        iconProps={{
                            icon: "user",
                        }}
                        labelProps={{
                            label: translateObject(guestLabel),
                        }}
                        inputProps={{
                            onChange: (e: React.FormEvent<HTMLInputElement>) =>
                                updateTicket("guests", +e.currentTarget.value),
                            value: section?.ticket?.guests ?? "",
                            type: "number",
                        }}
                    />
                    {/* {!!guestsFromTicket && <label style={{ color: TEXT_PLACEHOLDER , fontSize: '0.875rem' }}>{`${translateObject(waiterGuestsLabel)}${guestsFromTicket})`}</label>} */}
                </div>
            </div>
        </>
    );
};

export default Ticket;
