import AnswerType from "./types/AnswerType";

export default function formValidation(config: AnswerType | null, data: unknown[]): boolean {
  if(!config) return false;
  const { waiter, ticket } = config;
  const { ticketN, table, guests, amount } = ticket || {};

  if (!waiter || !ticketN || !table || !guests || !amount || data.length === 0) {
    return false;
  }

  return true;
}
