import axios from "axios";
import { useEffect, useState } from "react";
import TicketResponse from "../types/TicketResponse";
import { createAnswer, createTicket } from "./utils";
import usePreSurvey from "state/presurvey";

const useTicket = (id:string, url: string, ticketN?: number) => {
    const [loading, setLoading] = useState(false);
    const { dispatcher: preSurveyDispatcher } = usePreSurvey();

    useEffect(() => {
        if (!ticketN || !url) return;

        const delayDebounceFn = setTimeout(() => {
            setLoading(true);
            axios
                .get<TicketResponse>(`${url}/${ticketN}`)
                .then((res) => {
                    const newSection = createAnswer();
                    const { ticketN, amount, guests, table } = res.data.config;
                    newSection.ticket = createTicket(ticketN, table, guests, amount);
                    preSurveyDispatcher.setPreSurvey(id, newSection);
                })
                .catch((e) => {
                    alert(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [id, url, ticketN, preSurveyDispatcher]);

    return { loading };
};

export default useTicket;